<template>
  <transition name="show">
    <div
      v-show="open"
      class="sidebar"
    >
      <transition-group
        appear
        name="fade"
      >
        <div
          v-for="(item, index) in navElements"
          :key="item.key"
          :style="{ '--index': index }"
          class="column has-text-centered sidebar-element"
        >
          <router-link :to="item.routerLink">
            <div class="column">
              <b-icon
                size="is-small"
                :icon="item.icon"
              /><span
                class="has-text-centered is-size-7"
              >{{ item.name }}</span>
            </div>

            <!-- <div class="columns is-multiline"><div class="column is-12"> <b-icon size="is-small" :icon="item.icon"></b-icon></div>
            <div class="column is-12"> <span class="is-size-7">{{ item.name }}</span></div>
            </div> -->
            <!-- <br /> -->
          </router-link>
        </div>
      </transition-group>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: true,
    }
  },
  data: () => {
    return {
      navElements: [
        {
          key: "dashboard",
          name: "Dashboard",
          icon: "tachometer-alt",
          routerLink: "/dashboard",
        },
        {
          key: "analytics",
          name: "Analytics",
          icon: "chart-bar",
          routerLink: "/analytics",
        },
        // {
        //   key: 'account',
        //   name: 'Account',
        //   icon: 'user',
        //   routerLink: '/account'
        // },
        {
          key: "releases",
          name: "Releases",
          icon: "box-open",
          routerLink: "/releases",
        },
        {
          key: "settings",
          name: "Settings",
          icon: "cogs",
          routerLink: "/settings",
        },
        {
          key: "contact",
          name: "Involute",
          icon: "envelope",
          routerLink: "/contact",
        },
        // {
        //   key: 'logOut',
        //   name: 'Signout',
        //   icon: 'sign-out-alt',
        //   routerLink: '/login'
        // }
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.router-link-active {
  color: $link;
}

a {
  color: white;
}

.sidebar {
  height: 100%;
  width: 80px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  // background-color: rgba($color: $primary, $alpha: 0);
  // border-right: solid 1px $primary;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-top: 80px;
  // display: flex;
  // align-items: center;
  // justify-content: center;

  .sidebar-element {
    padding: 5px 0;
    cursor: pointer;
    color: $light;
  }
  // .sidebar-element:hover {
  //   color: $link;
  // }
}
.show {
  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateX(-60px);
  }
  &-enter-active,
  &-leave-active {
    transition: all 300ms;
  }
}
.fade {
  &-enter {
    opacity: 0;
    transform: translateX(-60px);
  }
  &-enter-active {
    transition: all 300ms ease-in-out;
    transition-delay: calc(100ms * var(--index));
  }
}

@media screen and (max-height: 450px) {
  .sidebar {
    padding-top: 5px;
  }
  .sidebar {
    font-size: 10px;
  }
}
</style>
