<template class="">
  <div class="columns">
    <div
      v-for="tile in singleAuctionInfoTiles"
      :key="tile.key"
      class="column is-half"
    >
      <Cardslot class="has-text-left">
        <template #header>
          {{ tile.id }}
        </template>
        <template #iconheader>
          <b-icon
            :icon="tile.icon"
            type="is-light"
            size="is-medium"
          />
        </template>
        <template #content>
          <div
            v-if="tile.id == 'Request'"
          >
            <tree-view
           
              class=""
              :data="singleAuction"
              :options="{modifiable: false, link: true, maxDepth: 1, rootObjectKey: singleAuction.auctionId}"
            />
            <!-- <ul>
              <li>
                <div class="columns">
                  <div class="column has-text-left">
                    Product:
                  </div> <div class="column has-text-right">
                    {{ singleAuction.request.pid }}
                  </div>
                </div>
              </li>
              <li>
                <div class="columns">
                  <div class="column has-text-left">
                    Date:
                  </div> <div class="column has-text-right">
                    {{ new Date(singleAuction.auctionEnd) }}
                  </div>
                </div>
              </li>
              <li>
                <div class="columns">
                  <div class="column has-text-left">
                    Client situation:
                  </div> <div class="column has-text-right">
                    {{ singleAuction.request.houseinfoUser }}
                  </div>
                </div>
              </li>
            </ul> -->
            
            <!-- <hr>
            <tree-view
              class="box"
              :data="singleAuction.request"
              :options="{modifiable: false, link: true}"
            /> -->
            <!-- Product: {{ singleAuction.request.pid }} <br> <br>
            Date: {{ singleAuction.request.timestamp }} <br> <br>
            clientSituation: Single, no Kids, 60m2 -->
            <!-- <hr> -->
            <!-- Product: {{ singleAuction.request.pid }}
            RequestDate: {{ singleAuction.request.timestamp }} -->
            <!-- {{ singleAuction.request }} -->
            <!-- <hr>
            <tree-view
              class="box"
              :data="singleAuction.request"
              :options="{modifiable: false, link: true}"
            /> -->
          </div>
          <div
            v-if="tile.id == 'AuctionResult'"
          >
            <Table
              sortable
              :input-data="tableData"
              :columns="['offer', 'insurer']"
            />
            <!-- <hr> -->
            <!-- <hr>
            <tree-view
              class="box"
              :data="singleAuction.auctionResult"
              :options="{modifiable: false, link: true}"
            /> -->
            <br> <br>
            <div class="title has-text-centered box">
              <a href="">
                <b-icon
                  
                  icon="download"
                /> <br> Download json
              </a>
            </div>
          </div>
        </template>
        <!-- <template #footer>
          <tree-view
            v-if="tile.id == 'AuctionResult'"
            class="box"
            :data="singleAuction.auctionResult"
            :options="{modifiable: false, link: true}"
          />
          <tree-view
            v-if="tile.id == 'Request'"
            class="box"
            :data="singleAuction.request"
            :options="{modifiable: false, link: true}"
          />
        </template> -->
      </Cardslot>
    </div>
  </div>
  <!-- <div
      v-if="singleAuction"
      class="column"
    >
      {{ singleAuction }}
    </div> -->
  <!-- <div class="column">
      <div class="box">
        <div class="title">
          Request
        </div>
      </div>
    </div>
    <div class="column">
      <div class="box">
        <div class="title">
          Auction result
        </div>
      </div>
    </div>
    <div class="column">
      <div class="box">
        <div class="title">
          Client
        </div>
      </div>
    </div> -->
  <!-- <div class="column">
      Auctionresult: biddingTable + auctionResult data
    </div><div class="column">
      Client: client id/segment + verificationdata
    </div> -->
</template>

<script>
// import { mapGetters } from "vuex";
import Cardslot from "./shared/cardslot.vue";
import Table from "./shared/charts/table.vue";

export default {
  components: {
    Cardslot,
    Table,
  },
  props: {
    singleAuction: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      singleAuctionInfoTiles: [
        { id: "Request", icon: "home", treeContent:  "singleAuction.request"},
        { id: "AuctionResult", icon: "gavel", treeContent: "singleAuction.request" },
        // { id: "Client", icon: "user", treeContent:  "singleAuction.request"},
      ],
      tableData: [
        { offerId: "xyz", offer: 13, insurer: "NN" },
        { offerId: "xdz", offer: 12, insurer: "AEGON" },
        { offerId: "xaz", offer: 14, insurer: "OHRA" },
        { offerId: "xyz", offer: 19, insurer: "AON" },
        { offerId: "xdz", offer: 9, insurer: "Centraal beheer" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
// .modalcss {
//     color: $primary
// }
</style>>
