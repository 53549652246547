<template>
  <div class="">
    <b-table
      ref="multiSortTable"
      :class="{ pointer: deepdive }"
      :data="data"
      :backend-sorting="backendSortingEnabled"
      :sort-multiple="sortMultiple"
      :sort-multiple-data="sortingPriority"
      :sort-multiple-key="customKey"
      :narrowed="narrowed"
      :paginated="paginated"
      :per-page="20"
      :hoverable="hoverable"
      :selected.sync="selected"
      :detailed="detailed"
      :deepdive="deepdive"
      sort-icon="chevron-up"
      @sort="sortPressed"
      @sorting-priority-removed="sortingPriorityRemoved"
    >
      <b-table-column
        v-for="column in columns"
        :key="column"
        v-slot="props"
        :field="column"
        :label="column"
        :sortable="sortable"
      >
        <template class="pointer">
          {{ props.row[column] }}
        </template>
      </b-table-column>

      <b-table-column
        v-if="button"
        
        
        label="json"
        :sortable="sortable"
      >
        <template class="has-text-link">
          <b-icon icon="download" />
        </template>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    inputData: {
      type: Array,
      default: null,
    },
    columns: {
      type: Array,
      default: null,
    },
    button: {
      type: Boolean,
      default: false
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    sortMultiple: {
      type: Boolean,
      default: false,
    },
    narrowed: {
      type: Boolean,
      default: false,
    },
    paginated: {
      type: Boolean,
      default: false,
    },
    hoverable: {
      type: Boolean,
      default: false,
    },
    detailed: {
      type: Boolean,
      default: false,
    },
    deepdive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // selected: this.inputData[0],
      selected: {},
      customKey: null,
      backendSortingEnabled: false,
      multiColumnSortingEnabled: true,
      // data: [],
      sortingPriority: [],
    };
  },
  watch: {
    selected(newValue) {
      if (this.deepdive && newValue) {
        this.getSingleAuction(newValue.auctionId);
      }
    },
  },
  created() {
    this.data = this.inputData;
  },
  methods: {
    ...mapActions(["getSingleAuction"]),
    // showWonAuctiondetail() {
    //   if(this.deepdive) {
        
    //   console.log(this.selected.auctionId);
    // }
    //   // console.log(this.selected.auctionId)
    //   // const auction = this.selected
    //   // this.getSingleAuction(auction.auctionId)
    // },

    // resetPriority () {
    //   this.$refs.multiSortTable.resetMultiSorting()

    //   // reset local backend sorting
    //   if (this.backendSortingEnabled) {
    //     this.sortingPriority = []
    //     this.loadAsyncData()
    //   }
    // },

    // Backend sorting
    sortingPriorityRemoved(field) {
      this.sortingPriority = this.sortingPriority.filter(
        (priority) => priority.field !== field
      );
      this.loadAsyncData(this.sortingPriority);
    },

    sortPressed(field, order, event) {
      if (this.backendSortingEnabled) {
        if (this.multiColumnSortingEnabled) {
          if ((this.customKey && event[this.customKey]) || !this.customKey) {
            const existingPriority = this.sortingPriority.filter(
              (i) => i.field === field
            )[0];
            if (existingPriority) {
              existingPriority.order =
                existingPriority.order === "desc" ? "asc" : "desc";
            } else {
              // request sorted data from backend
              this.sortingPriority.push({ field, order });
            }
            this.loadAsyncData(this.sortingPriority);
          } else {
            // request regular sorted data from backend
            this.sortingPriority = []; // [{field, order}]
            this.loadAsyncData([{ field, order }]);
          }
        }
      }
    },

    // "API request" for data
    // async loadAsyncData (sortingPriority = []) {
    //   const mockdata = JSON.parse(JSON.stringify(dataSource))
    //   // get data already sorted from the backend using sortingPriority
    //   this.data = orderBy(mockdata, sortingPriority.map(i => i.field), sortingPriority.map(i => i.order))
    // }
  },
};
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer
}
// table {
//   background-color: rgba($color: #000000, $alpha: 0) !important;
//   color: $light !important
// }
</style>
