import axios from "axios"
import router from "../router"

export default {
    // namespaced: true,
    state: {
        errormessage: "",
        iuid: null,
        genericloader: false,
        environment: process.env.NODE_ENV
    },
    getters: {
        isAuthenticated(state) {
            return !!state.iuid
        },
        genericloader(state) {
            return state.genericloader
        },
        errormessage(state) {
            return state.errormessage
        },
        iuid(state) {
            return state.iuid
        },
    },
    mutations: {
        authUser(state, payload) {
            // console.log(this.state.idToken)
            state.iuid = payload.iuid

        },

        clearAuthData(state) {
            state.iuid = null
        },

        clearerrormessage(state) {
            state.errormessage = null
        },


        toggleLoader(state, payload) {
            state.genericloader = payload
        },

        updateErrormessage(state, payload) {
            state.errormessage = payload
        },

    },
    actions: {

        async postLogin(context, loginData) {
            try {
                const response = await axios.post("/insurerlogin", loginData)

                if (response.status == 200) {
                    context.commit("authUser", {
                        iuid: response.headers.token
                    })
                    localStorage.setItem("iuid", response.headers.token)
                    router.push('/dashboard')

                }
            } catch (error) {
                console.log(error)
                // define specific error handling actions here
            }
        },

        // async commituser(context, response) {
        //     context.commit("authUser", {
        //         uid: response.headers.token
        //         // emailaddress: response.data.data.emailaddress
        //     })

        //     localStorage.setItem("uid", response.headers.token)
        //     // localStorage.setItem('emailaddress', response.data.data.emailaddress)
        //     // router.push('/dashboard')
        //     // dispatch('fetchUser')
        // },

        async tryAutoLogin(context) {

            const iuid = localStorage.getItem("iuid")


            if (iuid) {
                // context.commit('authUser', {uid: uid})
                context.commit("authUser", {
                    iuid: iuid
                });

            }

        },

        logout(context) {
            context.commit("clearAuthData");
            localStorage.removeItem("iuid");
            // localStorage.clear()
            router.push("/");
            location.reload();

        },

    }
}
