import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: '',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/dashboard.vue')
  // },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/dashboard.vue'),

  },
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/home.vue'),
    meta: { noAuthRequired: true }
  },
  // {
  //   path: '/clientstable',
  //   name: 'clientstable',
  //   component: () => import(/* webpackChunkName: "about" */ '../components/shared/clientstable.vue')
  // },
  {
    path: '/dashboard/openAuctions',
    name: 'openAuctions',
    component: () => import(/* webpackChunkName: "about" */ '../views/openAuctions.vue'),

  },
  {
    path: '/dashboard/closedAuctions',
    name: 'closedAuctions',
    component: () => import(/* webpackChunkName: "about" */ '../views/closedAuctions.vue'),

  },
  {
    path: '/dashboard/wonAuctions',
    name: 'wonAuctions',
    component: () => import(/* webpackChunkName: "about" */ '../views/wonAuctions.vue')
  },
  // {
  //   path: '/analytics',
  //   name: 'analytics',
  //   component: Analytics
  // },
  {
    path: '/analytics',
    name: 'analytics',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/analytics.vue')
  },
  {
    path: '/analytics/pricingAnalytics',
    name: 'pricingAnalytics',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/pricingAnalytics.vue')
  },
  {
    path: '/analytics/marketAnalytics',
    name: 'marketAnalytics',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/marketAnalytics.vue')
  },
  {
    path: '/releases',
    name: 'releases',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/releases.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(function (to, from, next) {

  if (!to.meta.noAuthRequired && !localStorage.getItem("iuid")) {
      next("/")
  } else if (!to.meta.requiresAuth && localStorage.getItem("iuid")) {
      next()
  } else {
    next()
  }
})

export default router
