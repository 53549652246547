<template>
  <div id="app">
    <Navbar @togglenav="navOpen = !navOpen" />

    <Sidebar
      v-if="isAuthenticated"
      :open="navOpen"
    />

    <Loader />
    <div class="main">
      <transition
        name="component-fade"
        mode="out-in"
      >
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import Navbar from "./components/navbar";
// import TheTopbar from './components/shared/TheTopbar'
import Sidebar from "./components/sidebar";
import Loader from "./components/shared/loader";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Sidebar,
    // TheTopbar
    Navbar,
    Loader,
  },
  data: () => {
    return {
      navOpen: true,
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated"])
  },
  mounted() {
    this.tryAutoLogin();
  },
  methods: {
    ...mapActions(["tryAutoLogin"]),
  },
};
</script>

<style lang="scss">
@import "./assets/styles/variables.scss";
@import "./assets/styles/stylesheet.scss";

.main {
  margin-left: 60px; /* Same as the width of the sidenav */
  // font-size: 28px; /* Increased text to enable scrolling */
  // padding: 0px 10px;
}

.component-fade-enter-active,
.component-fade-leave-active {
   transition: opacity 50ms ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
