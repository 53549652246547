import Vue from 'vue'
import Vuex from 'vuex'

import general from './general'
import auctions from './auctions'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    general,
    auctions
  }
})
