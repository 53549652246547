// import axios from "axios"
// import router from "../router"

import axios from "axios"
// import { DialogProgrammatic as Dialog } from 'buefy'
import { ModalProgrammatic as Modal } from 'buefy'
import singleAuctionComponent from '../components/singleAuction.vue'

export default {
    // namespaced: true,
    state: {
        wonAuctions: null,
        openAuctions: null,
        singleAuction: null,
        APIsourceData: [
            {
              id: "xyzsdfsds",
              myOffer: 16,
              winningOffer: 14,
              product: "contents",
              clientSegment: "segment 4",
              date: "January",
            },
            {
                id: "xyzsdfs",
                myOffer: 16,
                winningOffer: 16,
                product: "car",
                clientSegment: "segment 2",
                date: "January",
              },
              {
                id: "xyzsdfs",
                myOffer: 23,
                winningOffer: 17,
                product: "car",
                clientSegment: "segment 1",
                date: "January",
              },
            {
              id: "xyz01asd",
              myOffer: 20,
              winningOffer: 18,
              product: "car",
              clientSegment: "segment 4",
              date: "February",
            },
            {
                id: "xyz0sdf1",
                myOffer: 38,
                winningOffer: 31,
                product: "contents",
                clientSegment: "segment 4",
                date: "February",
              },
              {
                id: "xyz0sdf1",
                myOffer: 33,
                winningOffer: 31,
                product: "travel",
                clientSegment: "segment 2",
                date: "February",
              },
            {
              id: "xyz22",
              myOffer: 33,
              winningOffer: 33,
              product: "travel",
              clientSegment: "segment 1",
              date: "March",
            },
            {
              id: "xyz39",
              myOffer: 8,
              winningOffer: 5,
              product: "travel",
              clientSegment: "segment 2",
              date: "March",
            },
            {
                id: "xyz39",
                myOffer: 8,
                winningOffer: 5,
                product: "travel",
                clientSegment: "segment 2",
                date: "March",
              },
              {
                id: "xyz39",
                myOffer: 8,
                winningOffer: 5,
                product: "travel",
                clientSegment: "segment 2",
                date: "March",
              },
              {
                id: "xyz39",
                myOffer: 8,
                winningOffer: 5,
                product: "travel",
                clientSegment: "segment 2",
                date: "March",
              },
            {
              id: "xyz36",
              myOffer: 23,
              winningOffer: 21,
              product: "home",
              clientSegment: "segment 1",
              date: "April",
            },
            {
              id: "xyz33",
              myOffer: 16,
              winningOffer: 10,
              product: "contents",
              clientSegment: "segment 3",
              date: "April",
            },
            {
                id: "xyz13",
                myOffer: 16,
                winningOffer: 16,
                product: "car",
                clientSegment: "segment 2",
                date: "April",
              },
          ],
    },
    getters: {
        wonAuctions(state) {
            return state.wonAuctions
        },
        openAuctions(state) {
            return state.openAuctions
        },
        singleAuction(state) {
            return state.singleAuction
        },
        APIsourceData(state) {
            return state.APIsourceData
        }
    },
    mutations: {
        saveWonAuctions(state, payload) {
            state.wonAuctions = payload
        },
        saveOpenAuctions(state, payload) {
            state.openAuctions = payload
        },
        saveSingleAuction(state, payload) {
            state.singleAuction = payload
        }

    },
    actions: {

        async getWonAuctions(context) {
            let wonAuctions
            try {
                wonAuctions =  await axios.get("realm/insurer/auctions/won")
                // console.log(wonAuctions)

                if(wonAuctions.status == 200  && wonAuctions.data.data ) {
                    // console.log(wonAuctions.data.data)
                    context.commit("saveWonAuctions", wonAuctions.data.data)
                }
            } catch (error) {
                console.log(error)
            }

        },

        async getOpenAuctions(context) {
            let openAuctions
            try {
                openAuctions =  await axios.get("realm/insurer/auctions/open")
                // console.log(openAuctions.data)

                if(openAuctions.status == 200 && openAuctions.data.data ) {
                    // console.log(openAuctions.data)
                    context.commit("saveOpenAuctions", openAuctions.data.data)
                }
            } catch (error) {
                console.log(error)
            }

        },

        async getSingleAuction(context, auctionId) {
            let singleAuction
            try {
                singleAuction =  await axios.get("realm/insurer/auctions/single/" + auctionId  )

                if(singleAuction.status == 200 && singleAuction.data.data ) {
                    // console.log(singleAuction)

                    context.commit("saveSingleAuction", singleAuction.data.data)

   
                    // Dialog.alert({
                    //     title: "singleAuction fetched",
                    //     message: singleAuction.data.data,
                    //     size: "is-small",
                    //     type: "is-primary",
                    //     hasIcon: true
                    // })

                    // const singleAuctionComponent = {
                    //     props: singleAuction.data.data,
                    //     template: `
                    //         <div>singleAuction.data.data</div>
                    //     `
                    // }
                
                    
        
                            Modal.open({
                                props: {
                                    "singleAuction": singleAuction.data.data,
                                },
                                component: singleAuctionComponent,
                                ariaModal: true,
                                width: 1264,
                                // fullScreen: true,
                                scroll: 'clip',
                                customClass: "modal"
                                // "<ul> <li> <b> Af te ronden aanvragen </b> <br>" +
                                // this.requesttext +
                                // "</li> <li> <b>Actieve veilingen </b> <br>" +
                                // this.auctiontext +
                                // "</li><li> <b>Lopende verzekeringen </b> <br>" +
                                // this.insurancetext +
                                // "</li></ul>",
                            }) 
                            
                        
                            

                   

                    // Modal.open({
                        
                    //     // title: "singleAuction fetched",
                    //     content: singleAuction.data.data,
                    //     trapFocus: true
                    // })

                }
            } catch (error) {
                console.log(error)
            }

        }
       

    }
}
