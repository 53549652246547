import Chart from 'chart.js'
import 'vue-chartjs'

Chart.defaults.global.defaultFontColor = 'whitesmoke';
// Chart.defaults.line.cubicInterpolationMode = 'monotone'

// Chart.getDatasetMeta(1).hidden=true;

// Chart.canvas.parentNode.style.height = '128px';
// Chart.canvas.parentNode.style.width = '128px';
// Chart.defaults.global.maintainAspectRatio = false